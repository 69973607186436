import React, { createContext, useState, useContext } from "react";

const ISIContext = createContext({ isISIVisible: false, firstExpanded: false });

export const useISIContext = () => {
  const context = useContext(ISIContext);

  if (!context) {
    throw new Error(`ISIContext provider is not found`);
  }

  return context;
};

export const ISIProvider = ({ children }) => {
  const [isISIVisible, setIsISIVisible] = useState(false);
  const [firstExpanded, setFirstExpanded] = useState(false);

  const handleClick = () => {
    const isiWrapper = document.querySelector(".ISI_wrapper");

    if (!isiWrapper) {
      return;
    }

    const isiTopCord = isiWrapper.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({ left: 0, top: isiTopCord - 40, behavior: "smooth" });
  };
  return (
    <ISIContext.Provider
      value={{
        handleClick,
        isISIVisible,
        setIsISIVisible,
        firstExpanded,
        setFirstExpanded,
      }}
    >
      {children}
    </ISIContext.Provider>
  );
};
