import React from "react";

import { AppProvider } from "./src/context/AppContext";
import { ISIProvider } from "./src/providers";

import "./src/styles/global.css";
import "./src/styles/cookie-banner.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <ISIProvider>{element}</ISIProvider>
  </AppProvider>
);
